<template>
  <svg
    width="56"
    height="32"
    viewBox="0 0 56 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="56" height="32" rx="6" fill="#E3F2FD" />
    <path d="M37.92 15.6C35.9 10.91 32.1 8 28 8C23.9 8 20.1 10.91 18.08 15.6C18.0249 15.7262 17.9965 15.8623 17.9965 16C17.9965 16.1377 18.0249 16.2738 18.08 16.4C20.1 21.09 23.9 24 28 24C32.1 24 35.9 21.09 37.92 16.4C37.9751 16.2738 38.0035 16.1377 38.0035 16C38.0035 15.8623 37.9751 15.7262 37.92 15.6ZM28 22C24.83 22 21.83 19.71 20.1 16C21.83 12.29 24.83 10 28 10C31.17 10 34.17 12.29 35.9 16C34.17 19.71 31.17 22 28 22ZM28 12C27.2089 12 26.4355 12.2346 25.7777 12.6741C25.1199 13.1136 24.6072 13.7384 24.3045 14.4693C24.0017 15.2002 23.9225 16.0044 24.0769 16.7804C24.2312 17.5563 24.6122 18.269 25.1716 18.8284C25.731 19.3878 26.4437 19.7688 27.2196 19.9231C27.9956 20.0775 28.7998 19.9983 29.5307 19.6955C30.2616 19.3928 30.8864 18.8801 31.3259 18.2223C31.7654 17.5645 32 16.7911 32 16C32 14.9391 31.5786 13.9217 30.8284 13.1716C30.0783 12.4214 29.0609 12 28 12ZM28 18C27.6044 18 27.2178 17.8827 26.8889 17.6629C26.56 17.4432 26.3036 17.1308 26.1522 16.7654C26.0009 16.3999 25.9613 15.9978 26.0384 15.6098C26.1156 15.2219 26.3061 14.8655 26.5858 14.5858C26.8655 14.3061 27.2219 14.1156 27.6098 14.0384C27.9978 13.9613 28.3999 14.0009 28.7654 14.1522C29.1308 14.3036 29.4432 14.56 29.6629 14.8889C29.8827 15.2178 30 15.6044 30 16C30 16.5304 29.7893 17.0391 29.4142 17.4142C29.0391 17.7893 28.5304 18 28 18Z" fill="#2196F3" />
  </svg>
</template>

<script>
export default {
  name: 'Eye',
}
</script>
